import locationService from '../../../../services/notisphere/location'

export default {
    props: {
        providerOrgId: {type: Number, default: null}
    },
    data() {
        return {
            item: {
                location_id: null,
                idn: null,
                location_name: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip_code: null
            },
            formType: 'UPDATE',
            labelName: {
                location_id: 'Location Id',
                idn: 'IDN/Hospital Name',
                location_name: 'Location Name',
                address1: 'Address 1',
                address2: 'Address 2',
                city: 'City',
                state: 'State',
                zip_code: 'Zip'
            },
        }
    },
    methods: {
        forceRerenderUpdate(item) {
            this.formType = 'UPDATE'
            this.getLocation(item)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'UPDATE'){
                this.updateLocation()
            }
        },
        updateLocation() {
            locationService.pro_updateLocation(this.item, this.providerOrgId).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        getLocation(item) {
            locationService.pro_getLocation(item, this.providerOrgId).then(resp =>{
                if (!resp.error){
                    this.item = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        }
    }
}