import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const locationService = {
    //supplier
    sup_findLocation(payload, supplierOrgId) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            { query: 'idn', val: payload.idn ? encodeURIComponent(payload.idn) : payload.idn },
            { query: 'city', val: payload.city ? encodeURIComponent(payload.city) : payload.city },
            { query: 'state', val: payload.state ?encodeURIComponent(payload.state) : payload.state },
            { query: 'status', val: payload.verified },
        ]
        return http.get(`${API_ROOT}/notisphere/locations/supplier/${supplierOrgId}${param(arr)}`).then(resp => {
            return resp
        })
    },
    sup_getLocation(location_id, supplierOrgId) {
        return http.get(`${API_ROOT}/notisphere/locations/${location_id}/supplier/${supplierOrgId}`).then(resp => {
            return resp
        })
    },
    sup_createLocation(payload, supplierOrgId) {
        return http.post(`${API_ROOT}/notisphere/locations/supplier/${supplierOrgId}`, payload).then(resp => {
            return resp
        })
    },
    sup_updateLocation(payload, supplierOrgId) {
        return http.put(`${API_ROOT}/notisphere/locations/${payload.location_id}/supplier/${supplierOrgId}`, payload).then(resp => {
            return resp
        })
    },
    sup_deleteLocation(location_id, supplierOrgId) {
        return http.delete(`${API_ROOT}/notisphere/locations/${location_id}/supplier/${supplierOrgId}`).then(resp => {
            return resp
        })
    },
    sup_uploadFile (formData, supplierOrgId) {
        return http.post(`${API_ROOT}/notisphere/locations/upload-location-file/supplier/${supplierOrgId}`, formData).then(resp => {
            return resp
        })
    },
    sup_getSearchMeta (supplierOrgId) {
        return http.get(`${API_ROOT}/notisphere/locations/search-meta/supplier/${supplierOrgId}`).then(resp => {
            return resp
        })
    },

    //provider
    pro_findLocation(payload, providerOrgId) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            { query: 'supOrgId', val: payload.supplier_id },
            { query: 'city', val: payload.city },
            { query: 'state', val: payload.state },
            { query: 'verified', val: payload.verified },
            { query: 'status', val: payload.verified == null ? null : (payload.verified)},
        ]
        return http.get(`${API_ROOT}/notisphere/locations/provider/${providerOrgId}${param(arr)}`).then(resp => {
            return resp
        })
    },
    getCentralizedIntakeData(providerOrgId) {
        return http.get(`${API_ROOT}/notisphere/locations/provider/centralized-intake-data/${providerOrgId}`).then(resp => {
            return resp
        })
    },
    pro_getLocation(payload, providerOrgId) {
        return http.get(`${API_ROOT}/notisphere/locations/${payload.location_id}/provider/${providerOrgId}?supOrgId=${payload.supplier_org_id}`).then(resp => {
            return resp
        })
    },
    pro_updateLocation(payload, providerOrgId) {
        return http.put(`${API_ROOT}/notisphere/locations/${payload.location_id}/provider/${providerOrgId}`, payload).then(resp => {
            return resp
        })
    },
    pro_updateVerifyLocation(payload, providerOrgId) {
        return http.put(`${API_ROOT}/notisphere/locations/verify/provider/${providerOrgId}`, payload).then(resp => {
            return resp
        })
    },
    pro_getSearchMeta(providerOrgId) {
        return http.get(`${API_ROOT}/notisphere/locations/search-meta/provider/${providerOrgId}`).then(resp => {
            return resp
        })
    }
}

export default locationService
